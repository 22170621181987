<template>
  <UikitDialog
    :model-value="modelValue"
    full-height
    :with-mobile-menu="!isDesktop"
    :no-shadow="!isDesktop"
    :hide-backdrop="!isDesktop"
    close-others-on-mobile
    no-padding
    dark
    position="right"
    @handle-close="onCloseClick"
    @update:model-value="emits('update:modelValue', $event)"
  >
    <div class="wrapper">
      <div
        v-if="!ownerMode"
        class="community-header-image"
        :class="[isMale ? 'male' : 'female']"
      />

      <div class="content">
        <div
          v-if="user && ownerMode"
          class="flex flex-col items-center mb-6"
        >
          <UikitUserAvatar
            :user="user"
            size="l"
            class="avatar"
            style="margin-right: 0;"
          />

          <AccountOCommunityBadge
            v-if="user.communityBadge"
            :model-value="user.communityBadge"
            dark-mode
            class="badge badge--top-margined"
          />

          <div class="counts mt-4">
            {{ $t('account.OCommunityDialog.saleCount', purchaseCount) }} • {{ $t('account.OCommunityDialog.purchaseCount', saleCount) }}
          </div>

          <div class="counts-duration mb-4">
            {{ $t('account.OCommunityDialog.duration') }}
          </div>

          <div class="text text-center">
            {{ $t('account.OCommunityDialog.community') }}
          </div>
        </div>

        <div
          v-if="!ownerMode"
          class="mb-8"
        >
          <h1 class="main-title playfair mb-2">
            {{ $t('account.OCommunityDialog.communityTitle') }}
          </h1>

          <i18n-t
            keypath="account.OCommunityDialog.communityText"
            tag="p"
            class="main-subtitle mb-8"
          >
            <template #br>
              <br>
            </template>
          </i18n-t>

          <div
            v-if="user"
            class="flex mb-5"
          >
            <UikitUserAvatar
              :user="user"
              size="ml"
              class="avatar non-owner-avatar"
            />

            <div style="min-width: 0;">
              <div class="nickname mb-2">
                @{{ user.nickname }}
              </div>

              <AccountOCommunityBadge
                v-if="user.communityBadge"
                :model-value="user.communityBadge"
                dark-mode
                class="badge"
              />
            </div>
          </div>

          <i18n-t
            keypath="account.OCommunityDialog.communityText"
            tag="p"
            class="text"
          >
            <template #br>
              <br>
            </template>
          </i18n-t>

          <i18n-t
            v-if="user?.communityBadge?.status"
            keypath="account.OCommunityDialog.statusText"
            tag="p"
            class="text"
          >
            <template #statusName>
              <span class="text-status">{{ user.communityBadge?.status.name }}</span>
            </template>

            <template #purchases>
              {{ $t('account.OCommunityDialog.statusText.purchases', userStatusRequirements.purchaseRequired ?? 0) }}
            </template>

            <template #type>
              <template v-if="userStatusRequirements.type === 'AND'">
                {{ $t('and') }}
              </template>

              <template v-else>
                {{ $t('or') }}
              </template>
            </template>

            <template #sales>
              {{ $t('account.OCommunityDialog.statusText.sales', userStatusRequirements.saleRequired ?? 0) }}
            </template>
          </i18n-t>
        </div>

        <div
          v-if="ownerMode"
          class="mb-8"
        >
          <h3 class="title title--top-separated mb-2">
            {{ $t('account.OCommunityDialog.title1') }}
          </h3>

          <p class="text">
            {{ $t('account.OCommunityDialog.text1') }}
          </p>
        </div>

        <div class="mb-8">
          <h3 class="title title--top-separated mb-2">
            {{ $t('account.OCommunityDialog.title2') }}
          </h3>

          <p class="text">
            {{ $t('account.OCommunityDialog.text2') }}
          </p>
        </div>

        <div>
          <h3
            v-if="ownerMode"
            class="title title--top-separated mb-2"
          >
            {{ $t('account.OCommunityDialog.title3') }}
          </h3>

          <h3
            v-else
            class="title title--top-separated mb-2"
          >
            {{ $t('account.OCommunityDialog.title4') }}
          </h3>

          <div>
            <UIButton
              v-if="isMobile && isAppleDevice"
              :href="$t('appStoreLink')"
              target="_blank"
              type="black-border"
              size="s"
              class="mt-6"
              style="width: 100%;"
            >
              <UiIcon
                name="app-store-eng"
                style="width: 110px; height: 30px;"
              />
            </UIButton>

            <UIButton
              v-else-if="isMobile && isAndroidDevice && !isInternational"
              :href="apps.playMarket"
              target="_blank"
              type="black-border"
              size="s"
              class="mt-6"
              style="width: 100%;"
            >
              <UiIcon
                name="google-play-eng"
                style="width: 115px; height: 25px;"
              />
            </UIButton>

            <img
              v-else
              src="~assets/img/o-community/qr-code.svg"
              alt="QR code"
            >
          </div>
        </div>
      </div>
    </div>
  </UikitDialog>
</template>

<script setup lang="ts">
import { AnalyticsEvents } from '~/types/analytics';
import { Community } from '~/restAPI/Community';
import {
  CommunityInfos,
  CommunityStatusRequirement,
  PublicProfileDTO,
} from '~/restAPI/data-contracts';
import { useApi } from '~/restAPI';
import { useDevices } from '~/composables/useDevices';

import UIButton from '~/components/ui/UIButton.vue';

const props = defineProps<{
  modelValue: boolean
  user?: PublicProfileDTO
  ownerMode?: boolean
}>();
const emits = defineEmits<{
  (e: 'update:modelValue', value: boolean): void,
}>();

const { isMobile, isAppleDevice, isAndroidDevice } = useDevices();
const { createApiInstance } = useApi();
const { apps } = useCompanyInfo();
const { account } = useAccountStore();
const { isDesktop } = useUiBreakpoints();
const { showErrorDialog } = useErrorDialog();
const { $addEvent } = useNuxtApp();
const { isInternational } = useInternational();

const statsLoading = ref(false);
const privilegesLoading = ref(false);
const purchaseCount = ref(0);
const saleCount = ref(0);
const requirements = ref<CommunityInfos[]>([]);
const communityApi = createApiInstance(Community);

const isMale = computed(() => account.value.sex !== 'FEMALE');
const userStatusRequirements = computed<CommunityStatusRequirement>(() => {
  const defaultValue: CommunityStatusRequirement = {
    purchaseRequired: 0,
    saleRequired: 0,
    type: 'OR',
  };

  if (!props.user || !props.user.communityBadge?.status || privilegesLoading.value || props.ownerMode) return defaultValue;

  const statusCode = props.user.communityBadge.status.code!;
  const requirement = requirements.value.find((iterReq) => iterReq.status?.code === statusCode);

  return requirement?.requirements || defaultValue;
});

function getStatus() {
  statsLoading.value = true;

  communityApi.getStatus({ format: 'json' })
    .then((response) => response.data.data)
    .then((stats) => {
      purchaseCount.value = stats.purchaseCount || 0;
      saleCount.value = stats.saleCount || 0;
    })
    .catch(() => {
      showErrorDialog({
        title: 'Ошибка',
        subtitle: 'Ошибка при отправке запроса',
      });
    })
    .finally(() => {
      statsLoading.value = false;
    });
}

function getRequirements() {
  privilegesLoading.value = true;

  communityApi.getPrivilegesRequirements({ format: 'json' })
    .then((response) => response.data.data)
    .then((data) => {
      requirements.value = data;
    })
    .catch(() => showErrorDialog({
      title: 'Ошибка',
      subtitle: 'Ошибка при отправке запроса',
    }))
    .finally(() => {
      privilegesLoading.value = false;
    });
}

function onCloseClick() {
  $addEvent(AnalyticsEvents.OCommunityDialogClose, {
    page: props.ownerMode ? 'o!community' : 'o!community_stranger',
  });
}

watch(() => props.modelValue, (isOpened) => {
  if (!isOpened) return;

  $addEvent(AnalyticsEvents.OCommunityDialogView, {
    page: props.ownerMode ? 'o!community' : 'o!community_stranger',
  });

  if (props.ownerMode) {
    getStatus();
  } else {
    getRequirements();
  }
});
</script>

<style lang="scss" scoped>
@import "~/assets/scss/settings/index";

.community-header-image {
  width: 100%;
  height: 100px;
  margin-bottom: 40px;

  &.male {
    background: url('~/assets/img/o-community/watches.webp') no-repeat center;
    background-size: auto 100px;
  }
  &.female {
    background: url('~/assets/img/o-community/rings.webp') no-repeat center;
    background-size: auto 100px;
  }
}

.wrapper {
  padding-top: 89px;
  padding-bottom: 60px;
  font-family: $font-family-default;

  @include media-query(lg-and-up) {
    width: 504px;
  }
}

.content {
  padding: 0 16px;
  width: 100%;

  @include media-query(lg-and-up) {
    padding: 0 52px;
  }
}

.main-title {
  font-size: 36px;
  font-weight: 500;
  text-align: center;
  margin-top: 0;
}

.main-subtitle {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: $grey-3;
}

.title {
  position: relative;
  font-size: 18px;
  font-weight: 700;
  padding: 10px 0;

  &--top-separated:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 1px;
    display: block;
    background: $grey;
    opacity: 0.4;
  }
}

.badge {
  max-width: 100%;
  max-width: -moz-available;
  max-width: -webkit-fill-available;

  &--top-margined {
    margin-top: -6px;
  }
}

.nickname {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.text {
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 14px;
  line-height: 24px;
}

:deep(.text-status) {
  text-decoration: underline;
  font-weight: 600;
}

.counts {
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 18px;
  font-weight: 600;
}

.counts-duration {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: $grey-3;
  margin-top: 4px;
}

.avatar {
  flex-shrink: 0;
  border-width: 0 !important;
}

.non-owner-avatar {
  @apply mr-4;
  @apply text-4xl;

  @include media-query(lg-and-up) {
    margin-left: 16px;
  }
}
</style>
